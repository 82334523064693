import React from "react"

import { OFFICES } from "./constants"
import "../../assets/styles/commons/_common-kit.scss"
import * as styles from "./styles.module.scss"
import Office from "./Office"
import ContactForm from "../contact-form"

const Offices = ({
  screenWidth,
  handleOpenConfirmation,
  isOpenConfirmation,
  setConfirmationOption,
}) => {
  return (
    <>
      <ul className={styles.officesWrapper}>
        {OFFICES.map(item => (
          <Office key={item.id} item={item} screenWidth={screenWidth} />
        ))}
      </ul>
      <div>
        <div id="contact-form">
          <ContactForm
            formStatID="ContactPage"
            handleOpenConfirmation={handleOpenConfirmation}
            isOpenConfirmation={isOpenConfirmation}
            setConfirmationOption={setConfirmationOption}
          />
        </div>
      </div>
    </>
  )
}

export default Offices
