import React from "react"
import cx from "classnames"

import Layout from "../components/layout"
import "../assets/styles/commons/_common-kit.scss"
import * as contactStyles from "../assets/styles/contacts.module.scss"
import Headline from "../components/headline"
import Offices from "../components/offices"

const ContactPage = () => {
  return (
    <Layout pageTitle="Our Contacts">
      {({
        width,
        handleOpenConfirmation,
        isOpenConfirmation,
        setConfirmationOption,
      }) => {
        return (
          <main className={cx(contactStyles.contactBlock, "common-kit")}>
            <Headline>
              <div className={"headline"}>
                <div
                  className={cx(contactStyles.headline_grid, "headline_grid")}
                >
                  <p className={"strongText"}>contact us</p>
                  <h1 className={"withIndent"}>
                    <strong>LaSoft</strong> is
                    <br />
                    waiting to assist you
                  </h1>
                  <div className={"rightColumn"}>
                    <div>
                      <p>
                        Simply call, email, or use the form below to get in
                        touch
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Headline>
            <section className={cx("section", contactStyles.container)}>
              <Offices
                screenWidth={width}
                handleOpenConfirmation={handleOpenConfirmation}
                isOpenConfirmation={isOpenConfirmation}
                setConfirmationOption={setConfirmationOption}
              />
            </section>
          </main>
        )
      }}
    </Layout>
  )
}

export default ContactPage
